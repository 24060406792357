
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { Vue, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { ITypeOfDevice, IUser } from '@/utils/types'
import { PlusOutlined, FormOutlined, DeleteOutlined, ClockCircleOutlined } from '@ant-design/icons-vue'
import FireTypeOfDevice from '@/services/type-of-devices'
import TypeOfDeviceFormModal from '@/components/type-of-device/TypeOfDeviceFormModal.vue'
import UserTagById from '@/components/user/UserTagById.vue'
import { formatDateTime } from '@/utils/helpers'

@Options({
  components: {
    TypeOfDeviceFormModal,
    UserTagById,
    PlusOutlined,
    FormOutlined,
    DeleteOutlined,
    ClockCircleOutlined,
  },
  directives: { maska },
})
export default class TypeOfDeviceList extends Vue {
  loading = false
  modalVisible = false
  selectedTypeOfDeviceId = ''

  searchQuery = ''

  TypeOfDevices: ITypeOfDevice[] = []
  TypeOfDevicesFiltered: ITypeOfDevice[] = []

  columns = [
    {
      title: 'Type of device name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: ITypeOfDevice, b: ITypeOfDevice) => (a.name?.length || 0) - (b.name?.length || 0),
    },

    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      slots: { customRender: 'updatedAt' },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      slots: { customRender: 'action' },
    },
  ]

  formatDateTime = formatDateTime

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  @Watch('TypeOfDevices', { deep: true })
  TypeOfDevicesChanged() {
    // Get latest update items by reverse
    this.TypeOfDevicesFiltered = reverse(uniqBy(reverse([...this.TypeOfDevices]), 'id'))
  }

  onAdd() {
    this.selectedTypeOfDeviceId = ''
    this.modalVisible = true
  }

  onEdit(id: string) {
    this.selectedTypeOfDeviceId = id
    this.modalVisible = true
  }

  async onDelete(id: string) {
    await FireTypeOfDevice.delete(id)
    message.success('Deleted successfully')
  }

  async getTypeOfDevices() {
    FireTypeOfDevice.on((TypeOfDevice: ITypeOfDevice) => {
      if (TypeOfDevice.fireChangeType === 'removed') {
        this.TypeOfDevices = this.TypeOfDevices.filter((item) => item.id !== TypeOfDevice.id)
      } else {
        this.TypeOfDevices.push(TypeOfDevice)
      }
    })
  }

  beforeDestroy() {
    FireTypeOfDevice.off()
    this.TypeOfDevices = []
  }

  created() {
    this.getTypeOfDevices()
  }
}
