<template>
  <a-modal class="ant-modal--xl" :visible="modalVisible" @cancel="onCancel" :title="modalTitle" :loading="loading">
    <a-form ref="formRef" :model="form" :rules="rules" layout="vertical">
      <a-form-item label="Type of device name" name="name">
        <a-input size="large" v-model:value="form.name" autocomplete="name" placeholder="Type of device name" />
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" type="default" @click="onCancel">Cancel</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmit"> Save </a-button>
    </template>
  </a-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { message } from 'ant-design-vue'
import { ITypeOfDevice, IUser } from '@/utils/types'
import FireTypeOfDevice from '@/services/type-of-devices'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class TypeOfDeviceFormModal extends Vue {
  @Prop({ default: '' })
  id!: string

  @Prop()
  modalVisible!: boolean

  loading = false

  requiredRule = {
    required: true,
    message: 'This field is required',
    trigger: 'blur',
  }

  form: ITypeOfDevice = {
    name: '',
  }

  rules = {
    name: [this.requiredRule],
  }

  get modalTitle() {
    return this.isNew ? 'Add new type of device' : 'Edit type of device '
  }

  get userInfo(): IUser {
    return this.$store.getters.userInfo || {}
  }

  get isNew() {
    return !this.id
  }

  onSubmit() {
    this.$refs.formRef
      .validate()
      .then(() => {
        this.doSave()
      })
      .catch((error: unknown) => {
        console.log('error', error)
      })
  }

  async doSave() {
    this.loading = true
    if (!this.id && this.userInfo.id) {
      await FireTypeOfDevice.add(this.form)
    } else {
      await FireTypeOfDevice.update(this.form)
    }

    message.success('Saved successfully')
    this.loading = false
    this.onCancel()
  }

  async getTypeOfDevice() {
    return await FireTypeOfDevice.get(this.id)
  }

  @Emit('update:closeModal')
  onCancel() {
    return false
  }

  async created() {
    if (this.id) {
      const typeOfDevice = await this.getTypeOfDevice()
      this.form = { ...this.form, ...typeOfDevice }
    }
  }
}
</script>
