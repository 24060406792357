
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { IOrderElectronicDevice } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import { ShoppingOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import OrderElectronicDeviceFormModal from '@/components/order-electronic-device/OrderElectronicDeviceFormModal.vue'
import TypeOfDeviceById from '@/components/type-of-device/TypeOfDeviceById.vue'
import FireOrderElectronicDevice from '@/services/order-electronic-devices'
import { message } from 'ant-design-vue'
import { formatDate, formatDateTime } from '@/utils/helpers'

@Options({
  components: { ShoppingOutlined, TypeOfDeviceById, OrderElectronicDeviceFormModal, FormOutlined, DeleteOutlined },
  directives: { maska },
})
export default class OrderElectronicDevice extends Vue {
  loading = false
  searchQuery = ''
  selectedOrderElectronicDeviceId = ''
  modalVisible = false

  orderElectroicDevices: IOrderElectronicDevice[] = []
  orderElectroicDeviceFiltered: IOrderElectronicDevice[] = []

  columns = [
    {
      title: 'Date',
      dataIndex: 'dateAt',
      key: 'dateAt',
      slots: { customRender: 'dateAt' },
    },
    {
      title: 'Name',
      dataIndex: 'deviceName',
      key: 'deviceName',
    },
    {
      title: 'Type of devices',
      dataIndex: 'typeOfDeviceId',
      key: 'typeOfDeviceId',
      slots: { customRender: 'typeOfDeviceId' },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Device Information (Link)',
      dataIndex: 'deviceInformation',
      key: 'deviceInformation',
    },
    {
      title: 'Expired date',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      slots: { customRender: 'expiredAt' },
    },
    {
      title: 'User (If any)',
      dataIndex: 'user',
      key: 'user',
      slots: { customRender: 'user' },
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      slots: { customRender: 'updatedAt' },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      slots: { customRender: 'action' },
    },
  ]

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get orderElectronicDeviceInfo(): IOrderElectronicDevice {
    return this.$store.getters.orderElectronicDevices || {}
  }

  @Watch('orderElectroicDevices', { deep: true })
  orderElectronicDeviceChanged() {
    // Get latest update items by reverse
    this.orderElectroicDeviceFiltered = reverse(uniqBy(reverse([...this.orderElectroicDevices]), 'id'))
  }

  formatDate = formatDate
  formatDateTime = formatDateTime

  onAdd() {
    this.selectedOrderElectronicDeviceId = ''
    this.modalVisible = true
  }

  onEdit(id: string) {
    this.selectedOrderElectronicDeviceId = id
    this.modalVisible = true
  }

  @Watch('onDelete')
  async onDelete(id: string) {
    await FireOrderElectronicDevice.delete(id)
    message.success('Deleted successfully')
  }

  @Watch('searchQuery')
  orderElectronicDeviceFiltered() {
    const copyApps = this.orderElectroicDevices
    this.orderElectroicDeviceFiltered = copyApps.filter((item) => {
      const toSearch = `${item.deviceName}`.toLowerCase()
      const keyword = this.searchQuery.toLowerCase()
      const match1 = !this.searchQuery || toSearch.indexOf(keyword) >= 0
      return match1
    })
  }

  async getOrderElectronicDevices() {
    FireOrderElectronicDevice.on((orderElectronicDevice: IOrderElectronicDevice) => {
      if (orderElectronicDevice.id && orderElectronicDevice.fireChangeType === 'removed') {
        this.orderElectroicDevices = this.orderElectroicDevices.filter((item) => item.id !== orderElectronicDevice.id)
      } else {
        this.orderElectroicDevices.push(orderElectronicDevice)
      }
    })
    this.orderElectroicDeviceFiltered = this.orderElectroicDevices
  }

  beforeDestroy() {
    FireOrderElectronicDevice.off()
    this.orderElectroicDevices = []
  }

  created() {
    this.getOrderElectronicDevices()
  }
}
