<template>
  <a-spin :spinning="loading">
    <div class="pt-5 px-6">
      <div class="flex items-center flex-wrap">
        <h1 class="text-2xl flex-grow">
          <ShoppingOutlined />
          Order electronic device
        </h1>
        <div>
          <a-button size="large" @click.prevent="onAdd"> Add order electronic device </a-button>
        </div>
      </div>
      <p class="text-gray-400 text-base">Manage Order electronic device</p>
    </div>
    <a-divider />

    <div class="bg-gray-200 px-6 py-2 flex items-center">
      <div class="flex-grow text-gray-400">List order electronic device</div>
      <div>
        <a-input-search v-model:value="searchQuery" placeholder="search device" />
      </div>
    </div>
    <div class="px-4">
      <a-table :columns="columns" :data-source="orderElectroicDeviceFiltered" rowKey="id">
        <template #avatar="{ text: avatar }">
          <a-avatar :size="60" shape="square" v-if="avatar" :src="avatar" />
          <a-avatar :size="60" style="background-color: #1890ff" shape="square" v-else>U</a-avatar>
        </template>
        <template #dateAt="{ text: dateAt }">
          <a-tag color="orange">
            <span class="text-sm">{{ formatDate(dateAt) }}</span>
          </a-tag>
        </template>

        <template #diviceName="{ text: diviceName }">
          <a-tag color="orange">
            <span class="text-sm">{{ diviceName }}</span>
          </a-tag>
        </template>

        <template #typeOfDeviceId="{ text: typeOfDeviceId }">
          <TypeOfDeviceById :key="typeOfDeviceId" :id="typeOfDeviceId" />
        </template>

        <template #quantity="{ text: quantity }">
          <a-tag color="orange">
            <span class="text-sm">{{ quantity }}</span>
          </a-tag>
        </template>

        <template #deviceInformation="{ text: deviceInformation }">
          <a-tag color="orange">
            <span class="text-sm">{{ deviceInformation }}</span>
          </a-tag>
        </template>

        <template #expiredAt="{ text: expiredAt }">
          <a-tag color="orange">
            <span class="text-sm">{{ formatDate(expiredAt) }}</span>
          </a-tag>
        </template>

        <template #updatedAt="{ text: updatedAt }">
          <a-tag color="orange">
            <span class="text-sm">{{ formatDate(updatedAt) }}</span>
          </a-tag>
        </template>

        <template #action="{ text: id }">
          <a-space>
            <a-button default shape="circle" @click="onEdit(id)">
              <template #icon><FormOutlined /></template>
            </a-button>
            <a-popconfirm title="Are you sure？" ok-text="Yes" cancel-text="No" @confirm="onDelete(id)">
              <a-button danger shape="circle">
                <template #icon><DeleteOutlined /></template>
              </a-button>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
    </div>
  </a-spin>
  <OrderElectronicDeviceFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :id="selectedOrderElectronicDeviceId"
    @update:closeModal="modalVisible = false"
  />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import uniqBy from 'lodash/uniqBy'
import reverse from 'lodash/reverse'
import { IOrderElectronicDevice } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import { ShoppingOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import OrderElectronicDeviceFormModal from '@/components/order-electronic-device/OrderElectronicDeviceFormModal.vue'
import TypeOfDeviceById from '@/components/type-of-device/TypeOfDeviceById.vue'
import FireOrderElectronicDevice from '@/services/order-electronic-devices'
import { message } from 'ant-design-vue'
import { formatDate, formatDateTime } from '@/utils/helpers'

@Options({
  components: { ShoppingOutlined, TypeOfDeviceById, OrderElectronicDeviceFormModal, FormOutlined, DeleteOutlined },
  directives: { maska },
})
export default class OrderElectronicDevice extends Vue {
  loading = false
  searchQuery = ''
  selectedOrderElectronicDeviceId = ''
  modalVisible = false

  orderElectroicDevices: IOrderElectronicDevice[] = []
  orderElectroicDeviceFiltered: IOrderElectronicDevice[] = []

  columns = [
    {
      title: 'Date',
      dataIndex: 'dateAt',
      key: 'dateAt',
      slots: { customRender: 'dateAt' },
    },
    {
      title: 'Name',
      dataIndex: 'deviceName',
      key: 'deviceName',
    },
    {
      title: 'Type of devices',
      dataIndex: 'typeOfDeviceId',
      key: 'typeOfDeviceId',
      slots: { customRender: 'typeOfDeviceId' },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Device Information (Link)',
      dataIndex: 'deviceInformation',
      key: 'deviceInformation',
    },
    {
      title: 'Expired date',
      dataIndex: 'expiredAt',
      key: 'expiredAt',
      slots: { customRender: 'expiredAt' },
    },
    {
      title: 'User (If any)',
      dataIndex: 'user',
      key: 'user',
      slots: { customRender: 'user' },
    },
    {
      title: 'Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      slots: { customRender: 'updatedAt' },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: 100,
      slots: { customRender: 'action' },
    },
  ]

  get isAuth() {
    return this.$store.getters.isAuth
  }

  get orderElectronicDeviceInfo(): IOrderElectronicDevice {
    return this.$store.getters.orderElectronicDevices || {}
  }

  @Watch('orderElectroicDevices', { deep: true })
  orderElectronicDeviceChanged() {
    // Get latest update items by reverse
    this.orderElectroicDeviceFiltered = reverse(uniqBy(reverse([...this.orderElectroicDevices]), 'id'))
  }

  formatDate = formatDate
  formatDateTime = formatDateTime

  onAdd() {
    this.selectedOrderElectronicDeviceId = ''
    this.modalVisible = true
  }

  onEdit(id: string) {
    this.selectedOrderElectronicDeviceId = id
    this.modalVisible = true
  }

  @Watch('onDelete')
  async onDelete(id: string) {
    await FireOrderElectronicDevice.delete(id)
    message.success('Deleted successfully')
  }

  @Watch('searchQuery')
  orderElectronicDeviceFiltered() {
    const copyApps = this.orderElectroicDevices
    this.orderElectroicDeviceFiltered = copyApps.filter((item) => {
      const toSearch = `${item.deviceName}`.toLowerCase()
      const keyword = this.searchQuery.toLowerCase()
      const match1 = !this.searchQuery || toSearch.indexOf(keyword) >= 0
      return match1
    })
  }

  async getOrderElectronicDevices() {
    FireOrderElectronicDevice.on((orderElectronicDevice: IOrderElectronicDevice) => {
      if (orderElectronicDevice.id && orderElectronicDevice.fireChangeType === 'removed') {
        this.orderElectroicDevices = this.orderElectroicDevices.filter((item) => item.id !== orderElectronicDevice.id)
      } else {
        this.orderElectroicDevices.push(orderElectronicDevice)
      }
    })
    this.orderElectroicDeviceFiltered = this.orderElectroicDevices
  }

  beforeDestroy() {
    FireOrderElectronicDevice.off()
    this.orderElectroicDevices = []
  }

  created() {
    this.getOrderElectronicDevices()
  }
}
</script>
